<template>
  <div>
    <!-- <div v-if="exchange == 'kraken' && tradingCategory == 'futures'">
      <kraken-futures-trade :symbol="symbol" @transferTrade="reveiceTransferTrade"></kraken-futures-trade>
    </div>
    <div v-if="exchange == 'okex'">
      <okex-swap-trade :tradingCategory="tradingCategory" :symbol="symbol" @transferTrade="reveiceTransferTrade"></okex-swap-trade>
    </div> -->
    <!-- **************** 图表选择 **************** -->
    <div class="ml-3 mb-1 fs-12">
      <span @click="interval = 1" class="pointer mr-1" :class="interval == 1 ? 'lineblue--text':'grey--text'">{{ $t('inteval.1h') }}</span>
      <span @click="interval = 2" class="pointer ml-1 mr-1" :class="interval == 2 ? 'lineblue--text':'grey--text'">{{ $t('inteval.2h') }}</span>
      <span @click="interval = 4" class="pointer ml-1 mr-1" :class="interval == 4 ? 'lineblue--text':'grey--text'">{{ $t('inteval.4h') }}</span>
      <span @click="interval = 6" class="pointer ml-1 mr-1" :class="interval == 6 ? 'lineblue--text':'grey--text'">{{ $t('inteval.6h') }}</span>
      <span @click="interval = 12" class="pointer ml-1 mr-1" :class="interval == 12 ? 'lineblue--text':'grey--text'">{{ $t('inteval.12h') }}</span>
      <span @click="interval = 24" class="pointer ml-1 mr-1" :class="interval == 24 ? 'lineblue--text':'grey--text'">{{ $t('inteval.1d') }}</span>
      <span @click="interval = 72" class="pointer ml-1 mr-1" :class="interval == 72 ? 'lineblue--text':'grey--text'">{{ $t('inteval.3d') }}</span>
      <span @click="interval = 168" class="pointer ml-1 mr-1" :class="interval == 168 ? 'lineblue--text':'grey--text'">{{ $t('inteval.1w') }}</span>
      <span>
        <v-menu :nudge-width="70" :nudge-bottom="20" :max-height="350" :min-width="120" class="z-index">
          <template v-slot:activator="{ on }">
            <span v-on="on" class="pointer lineblue--text mr-1 ml-5">{{ timeZone.text }}</span>
          </template>
          <v-list>
            <v-list-item v-for="(timeZoneObject, i) in timeZones" :key="i" @click="timeZone = timeZoneObject">
              <v-list-item-title>{{ timeZoneObject.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
      <span @click="chartRangeSelector = 'auto'" class="pointer ml-5 mr-1" :class="chartRangeSelector == 'auto' ? 'lineblue--text':'grey--text'">
        {{ $t('common.auto') }}
      </span>
      <span @click="chartRangeSelector = '30'" class="pointer mr-1" :class="chartRangeSelector == '30' ? 'lineblue--text':'grey--text'">
        30
      </span>
      <span @click="chartRangeSelector = '90'" class="pointer mr-1" :class="chartRangeSelector == '90' ? 'lineblue--text':'grey--text'">
        90
      </span>
      <span @click="chartRangeSelector = '180'" class="pointer mr-1" :class="chartRangeSelector == '180' ? 'lineblue--text':'grey--text'">
        180
      </span>
      <span @click="chartRangeSelector = '365'" class="pointer mr-1" :class="chartRangeSelector == '365' ? 'lineblue--text':'grey--text'">
        365
      </span>
      <span @click="chartRangeSelector = 'all'" class="pointer ml-1" :class="chartRangeSelector == 'all' ? 'lineblue--text':'grey--text'" :title="$t('highcharts.autoMergedCandleTitle')">
        {{ $t('common.all') }}
      </span>
      <!-- **************** 操作按钮 **************** -->
      <span class="mr-3 float-right" v-if="responsive == 'PC'">
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.deleteChartPlotLine')" @click="deleteChartPlotLine" v-show="selectedDeleteChartPlotLineId != null">mdi-delete</v-icon>
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.addChartPlotLines')" @click="chartPlotLinesDialog = !chartPlotLinesDialog">mdi-pen</v-icon>
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.refresh')" @click="getHeikinAshiChartData(true)">mdi-refresh</v-icon>
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.hideOrderPlotLines')" @click="hideOrderPlotLines = !hideOrderPlotLines">{{ hideOrderPlotLines ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('highcharts.candleStyle')" @click="hollowCandleStyle = !hollowCandleStyle">{{ hollowCandleStyle ? 'icon-candlestick-hollow' : 'icon-candlestick-solid' }}</v-icon>
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('highcharts.renkoChart.snapshotAndShare')" @click="generateImage">mdi-camera</v-icon>
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.fullScreen')" @click="toggleFullscreen">mdi-fullscreen</v-icon>
      </span>
      <div class="mr-3 mt-3 text-right" v-if="responsive == 'mobile'">
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.deleteChartPlotLine')" @click="deleteChartPlotLine" v-show="selectedDeleteChartPlotLineId != null">mdi-delete</v-icon>
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.addChartPlotLines')" @click="chartPlotLinesDialog = !chartPlotLinesDialog">mdi-pen</v-icon>
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.refresh')" @click="getHeikinAshiChartData(true)">mdi-refresh</v-icon>
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.hideOrderPlotLines')" @click="hideOrderPlotLines = !hideOrderPlotLines">{{ hideOrderPlotLines ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('highcharts.candleStyle')" @click="hollowCandleStyle = !hollowCandleStyle">{{ hollowCandleStyle ? 'icon-candlestick-hollow' : 'icon-candlestick-solid' }}</v-icon>
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('highcharts.renkoChart.snapshotAndShare')" @click="generateImage">mdi-camera</v-icon>
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.fullScreen')" @click="toggleFullscreen">mdi-fullscreen</v-icon>
      </div>
    </div>
    <!-- **************** 核心内容 **************** -->
    <div ref="heikinAshiChartImage">
      <div class="chart-title ml-3 fs-24 grey--text" v-show="isTakingScreenshot">
        <span>Heikin Ashi</span>
        <span class="ml-8">{{ currency }}</span>
        <span class="ml-8">{{ getIntervalLabel(interval) }}</span>
        <span class="ml-8">{{ timeZone.text }}</span>
      </div>
      <div :id="id"  style="height: 500px;"></div>
      <div class="chart-credits mt-5" v-show="isTakingScreenshot">
        <fi-logo></fi-logo>
        <div class="chart-credits-text">iob.fi Charts</div>
      </div>
    </div>
    <!-- **************** 截图弹窗 ***************** -->
    <v-dialog v-model="heikinAshiChartImageDialog" persistent width="450">
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("highcharts.renkoChart.imageURL")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="heikinAshiChartImageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div v-show="!uploadBase64Loading" class="mt-3">
              <v-text-field id="imageHttpUrl" v-model="imageHttpUrl" single-line outlined disabled hide-details class="mt-3 mb-3"></v-text-field>
              <v-btn text small class="text-none lineblue--text pl-0" @click="saveImage()">{{$t("common.save")}}</v-btn>
              <v-btn text small class="text-none lineblue--text" v-clipboard:copy="imageHttpUrl" v-clipboard:success="copySuccess" v-clipboard:error="copyError">{{$t("common.copy")}}</v-btn>
              <v-btn text small class="text-none lineblue--text" :href="imageHttpUrl" target="_black">{{$t("common.open")}}</v-btn>
            </div>
            <div v-show="uploadBase64Loading" class="mt-3 text-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- **************** 图表指示线弹窗 ***************** -->
      <v-dialog v-model="chartPlotLinesDialog" persistent width="450">
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("common.addChartPlotLines")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="chartPlotLinesDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="mt-3">
              <v-form ref="chartPlotLineParamForm" v-model="chartPlotLineParamValid" lazy-validation>
                <v-row align="center">
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-text-field v-model="currency" :label="$t('assets.currency')" readonly></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-text-field type="number" v-model="chartPlotLineParam.value" min="0" :label="$t('trading.price')"></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-slider v-model="chartPlotLineParam.width" color="grey" :label="$t('common.width')" min="1" max="10" thumb-label="always" class="mt-12"></v-slider>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-select v-model="chartPlotLineParam.dashStyle" :items="plotlineDashStyles" :label="$t('menu.style')"></v-select>
                    <a href="https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/plotoptions/series-dashstyle-all/" target="_blank" class="del-underline mt-6">
                      <v-icon class="ml-3">mdi-information-outline</v-icon>
                    </a>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-btn x-small tile dark small block :title="$t('menu.color')" :color="chartPlotLineParam.color" @click="colorPickerDialog = true">{{ $t('common.plotLineColor') }}</v-btn>
                  </v-col>
                  <v-col class="d-flex justify-end" cols="12" sm="6">
                    <v-btn x-small tile dark small :title="$t('common.ok')" :loading="chartPlotLineSubmitLoading" class="blue" @click="submitChartPlotLine">{{ $t('common.ok') }}</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- **************** 颜色选择器弹窗 ***************** -->
      <color-picker-dialog :colorPickerDialog="colorPickerDialog" @emitColorPickerDialog="receiveColorPickerDialog"></color-picker-dialog>
  </div>
</template>
<script>
  import Vue from 'vue';
  import config from '@/config.js';
  import FiLogo from '@/components/common/FiLogo.vue';
  import ColorPickerDialog from '@/components/dialog/ColorPickerDialog.vue';
  import Highcharts from 'highcharts/highstock';
  import { mapGetters } from "vuex";
  import html2canvas from 'html2canvas';
  // import KrakenFuturesTrade from '@/components/marketPrice/KrakenFuturesTrade.vue';
  // import OkexSwapTrade from '@/components/marketPrice/OkexSwapTrade.vue';
  export default {
    data() {
      return {
        interval: 1,
        // 域名
        domainName: config.domainName,
        // 数据数组
        seriesData: [],
        // 实时的数据数组
        realtimeSeriesData: [],
        // 成交量数据数组
        // volumeData: [],
        // 一组最新成交数据价格和时间对象的数据
        // 示例: [9100, 1595225751599]
        realtimeTrade: [],
        // 图表id
        id: 'heikinAshiChart',
        // 生成的整个图表对象
        chart: null,
        // 图表加载中
        chartLoading: false,
        // 选择器的按钮
        rangeSelectorButtons: [],
        // heikinAshiChart图片弹窗
        heikinAshiChartImageDialog: false,
        // 是否正在截图
        isTakingScreenshot: false,
        // heikinAshiChart图片base64地址
        imageBase64Url: null,
        // heikinAshiChart图片http地址
        imageHttpUrl: null,
        // 上传图片加载中
        uploadBase64Loading: false,
        // 最后一组K线数据
        // 示例: [1595225751599, o, h, l, c, v, dateTime]
        lastHeikinAshiChartData: [],
        // 下一个块的时间戳
        nextBarTimestamp: 0,
        // 标示线文本距离左侧宽度
        plotLinesLabelTextWidth: 100,
        // 自动刷新图表定时器
        autoRetreshTimer: null,
        // 时区
        timeZone: {
          text: 'UTC',
          value: 0
        },
        // 图表范围选择器，auto/all
        chartRangeSelector: 'auto',
        // 图表起始时间
        chartStartDatetime: null,
        // 是否隐藏订单指示线
        hideOrderPlotLines: false,
        // 空心样式
        hollowCandleStyle: false,
        // 颜色选择器弹窗
        colorPickerDialog: false,
        // 图表指示线弹窗
        chartPlotLinesDialog: false,
        // 样式
        plotlineDashStyles: ['Solid', 'ShortDash', 'ShortDot'],
        // 图表指示线验证
        chartPlotLineParamValid: false,
        // 图表指示线提交加载中
        chartPlotLineSubmitLoading: false,
        // 图表指示线参数
        chartPlotLineParam: {
          currency: null,
          chartType: 'HeikinAshi',
          color: '#9e9e9e',
          value: 0,
          width: 1,
          dashStyle: 'Solid'
        },
        // 删除的图表指示线id
        selectedDeleteChartPlotLineId: null,
        // 数据选项
        option:{
          colors: ['#95CEFF'],
          // 图表类型
          chart: {
            backgroundColor: this.darkMode ? 'dark' : '',
            renderTo: 'heikinAshiChart',
            type: 'column',
            className: 'crosshair',
            events: {
              click: function(e) {}
            }
          },
          // 范围选择器
          rangeSelector: {
            enabled: false,
            allButtonsEnabled: true,
            inputEnabled: false,
            buttonPosition: {
              x: 0
            },
            // "millisecond", "second", "minute", "hour", "day", "week", "month", "ytd", "all"
            buttons: [{
              type: 'all',
              text: 'All'
            }],
            selected: 0,
            buttonTheme: {
              fill: "#eee",
              stroke: "none",
              r: 0,
              style: {
                  color: "#666",
                  fill: "#2196f3"
              },
              states: {
                hover: {
                    fill: "#eee",
                },
                select: {
                  fill: "#eee",
                  style: {
                    color: "#2196f3"
                  }
                }
              },
            },
            selected: 0
          },
          // 标题
          title: {
            text: null
          },
          // 图例导航
          legend: {
            enabled: false
          },
          // X轴
          xAxis: [{
            // visible: false
            crosshair: {
              dashStyle: 'shortdash',
              // 是否与最近的点对齐，为 true 时十字准星线会自动的对齐到最近的点，为 false 则是当前鼠标的位置
              snap: true,
              width: 1,
              label: {
                enabled: true,
                backgroundColor: '#FEE108',
                style:{
                	color:'#212121',
                },
                format: '{value:%m/%d %H:%M}'
              }
            },
            // 刻度线长度
            tickLength: 0,
            tickWidth: 1,
            tickPosition: 'inside',
            type: 'datetime',
            dateTimeLabelFormats: {
              millisecond: '%m/%d %H:%M',
              second: '%m/%d %H:%M',
              minute: '%m/%d %H:%M',
              hour: '%m/%d %H:%M',
              day: '%m/%d',
              week: '%m/%d',
              month: '%m/%d',
              year: '%Y'
            }
          }],
          // y轴
          yAxis: [{
            title: {
              text: null
            },
            crosshair: {
              dashStyle: 'shortdash',
              // 是否与最近的点对齐，为 true 时十字准星线会自动的对齐到最近的点，为 false 则是当前鼠标的位置
              snap: false,
              width: 1,
              label: {
                enabled: true,
                backgroundColor: '#FEE108',
                style:{
                	color:'#212121',
                },
                format: '{value:.2f}'
              }
            },
            offset: 120,
            gridLineWidth: 0,
            opposite: true,
            labels: {
              align: "left",
              x: -20,
              format: '{value:.2f}'
            },
            height: '100%',
            plotLines: [{
              id: 'closePricePlotLine',
              value: null,
              color: '#9e9e9e',
              dashStyle: 'shortdash',
              width: 2,
              label: {
                useHTML: true,
                x: 0,
                text: ''
              }
            }]
          }
          // , {
          //   title: {
          //     text: null
          //   },
          //   labels: {
          //     align: 'left',
          //     x: -10
          //   },
          //   crosshair: {
          //     dashStyle: 'shortdash',
          //     // 是否与最近的点对齐，为 true 时十字准星线会自动的对齐到最近的点，为 false 则是当前鼠标的位置
          //     snap: false,
          //     width: 1,
          //     label: {
          //       enabled: true,
          //       backgroundColor: '#ff6f61',
          //       format: '{value:.0f}'
          //     }
          //   },
          //   offset: 40,
          //   gridLineWidth: 0,
          //   opposite: true,
          //   top: '80%',
          //   height: '20%'
          // }
          ],
          // 导航器
          navigator: {
            enabled: true,
            // adaptToUpdatedData: false,
            series: {
              color: '',
              // 线宽0
              lineWidth: 1,
              // 透明度0
              fillOpacity: 0
            },
            // 格式化时间选择器的时间格式
            xAxis: {
              labels: {
                format: '{value: %m/%d}'
              }
            },
            yAxis: {
              offset: 120,
              opposite: true,
              labels: {
                align: "left",
                x: -20
              }
            }
          },
          // 鼠标悬浮提示框
          tooltip: {
            // 当提示框被共享时，整个绘图区都将捕捉鼠标指针的移动。
            shared: true,
            crosshairs: [true, false],
            split: false,
            useHTML: true,
            hideDelay: 0,
            borderColor:'black',
            backgroundColor:'rgba(247,247,247,1)',
            shadow:true,
            headerFormat: '',
            pointFormat:'<span><b>{series.name}</b><br/><h6></h6>'+
                        '<b>'+ this.$t('ohlc.open') +': </b>{point.open}<br/>'+
                        '<b>'+ this.$t('ohlc.high') +': </b>{point.high}<br/>'+
                        '<b>'+ this.$t('ohlc.low') +': </b>{point.low}<br/>'+
                        '<b>'+ this.$t('ohlc.close') +': </b>{point.close}<br/>',
            footerFormat: '<b>'+ this.$t('ohlc.time') + ': </b>{point.key}</span>',
            "style": {
                zIndex: 9999,
              },
            shape: 'square',
            dateTimeLabelFormats: {
              millisecond: '%m/%d/%Y %H:%M',
              second: '%m/%d/%Y %H:%M',
              minute: '%m/%d/%Y %H:%M',
              hour: '%m/%d/%Y %H:%M',
              day: '%m/%d %H:%M',
              week: '%m/%d',
              month: '%m/%Y',
              year: '%Y'
            },
            // 提示框固定位置
            positioner: function() {
              return {
                x: 10,
                y: 30
              }
            }
          },
          // 关闭滑块
          scrollbar: {
            enabled : false
          },
          // 数据块的设置
          plotOptions: {
            candlestick: {
              // cursor: 'pointer',
              pointPadding: 0,
              events: { },
              dataLabels:{
                allowOverlap:true,
                enabled: false,
                style: {
                  textOutline: '0'
                },
                formatter:function(){
                  if(this.point.high == this.series.dataMax){
                    return this.series.dataMax;
                  }
                  if(this.point.low == this.series.dataMin) {
                    return this.series.dataMin;
                  }
                }
              }
            }
          },
          // 数据
          series: [{
            id: 'dataseries',
            name: '',
            type: 'candlestick',
            data: [],
            tooltip: {
              valueDecimals: 2
            },
            color: '#ef5350',
            lineColor: '#ef5350',
            upColor: '#25a69a',
            upLineColor: '#25a69a'
          }
          // ,{
          //   id: 'volumeseries',
          //   name: '',
          //   type: 'column',
          //   data: [],
          //   yAxis: 1,
          //   tooltip: {
          //     pointFormat: '<b>'+ this.$t('ohlc.volume') +': </b>{point.y}<br/>'
          //   }
          // }
          ],
          noData: {
            style: {
              fontWeight: 'bold',
              fontSize: '15px',
              color: '#9e9e9e'
            }
          },
          // 版权信息
          credits: {
            enabled: false,
            href: config.domainName,
            text: this.$t('highcharts.dataFromIOB')
          },
          // 导出模块
          exporting: {
            enabled: false,
            filename: 'HeikinAshi-Chart',
            buttons: {
              contextButton: {
                align: "left",
                verticalAlign: "top",
                x: 1,
                y: 0
              }
            }
          },
          navigation: {
            buttonOptions: {
                symbolStroke:'black',
                theme: {
                    fill: '#EEEEEE',
                }
            }
          },
          loading: {
            style: {
              backgroundColor: 'silver'
            },
            labelStyle: {
              color: 'white',
              fontSize: '36px'
            }
          }
        },
      }
    },
    props: [ 'exchange', 'tradingCategory', 'symbol', 'currency', 'openPrices', 'openPositionsPrices' ],
    watch: {
      // 监听HeikinAshi改变重新渲染图表
      interval(newVal, oldVal){
        this.realtimeTrade = [];
        this.lastHeikinAshiChartData = [];
        this.setParams();
        this.calsChartStartDatetime();
        this.getHeikinAshiChartData(true);
      },
      exchange(newVal, oldVal){
        this.realtimeTrade = [];
        this.lastHeikinAshiChartData = [];
        this.getHeikinAshiChartData(true);
      },
      tradingCategory(newVal, oldVal){
        this.realtimeTrade = [];
        this.lastHeikinAshiChartData = [];
        this.getHeikinAshiChartData(true);
      },
      symbol(newVal, oldVal){
        this.realtimeTrade = [];
        this.lastHeikinAshiChartData = [];
        this.getHeikinAshiChartData(true);
      },
      'timeZone.value'(newVal, oldVal){
        this.realtimeTrade = [];
        this.lastHeikinAshiChartData = [];
        this.setParams();
        this.getHeikinAshiChartData(true);
      },
      hideOrderPlotLines(newVal, oldVal){
        this.setParams();
      },
      hollowCandleStyle(newVal, oldVal){
        this.setParams();
        if (newVal) {
          // 透明色
          this.option.series[0].upColor = '#ffffff00';
        } else {
          this.option.series[0].upColor = '#25a69a';
        }
        this.getHeikinAshiChartData(true);
      },
      // 监听实时的Trade数据渲染图表
      realtimeSeriesData(newVal, oldVal){
        if (this.chart.series != null && this.chart.series.length > 0 && this.chart.series[0] != null) {
          this.chart.series[0].setData(newVal);
        }
      },
      // volumeData(newVal, oldVal){
      //   this.chart.series[1].setData(newVal);
      // },
      // 监听加载中
      chartLoading(newVal, oldVal){
        if (this.chart) {
          if (newVal) {
            this.chart.showLoading();
          } else {
            this.chart.hideLoading();
          }
        }
      },
      // 监听开仓价的标示线
      openPrices(newVal, oldVal){
        if (newVal == null) {
          return;
        }
        let oldOpenLongPrices = oldVal.openLongPrices;
        let oldOpenShortPrices = oldVal.openShortPrices;
        // 删除旧的标示线
        for(let i = 0, len = oldOpenLongPrices.length; i < len; i++){
          this.chart.yAxis[0].removePlotLine('openLongPricePlotLine' + oldOpenLongPrices[i]);
        }
        for(let i = 0, len = oldOpenShortPrices.length; i < len; i++){
          this.chart.yAxis[0].removePlotLine('openShortPricePlotLine' + oldOpenShortPrices[i]);
        }
        // 添加新的标示线
        // 开多
        let openLongPrices = newVal.openLongPrices;
        for(let i = 0, len = openLongPrices.length; i < len; i++){
          // 开多仓价的横线
          let plotLine = {
            id: 'openLongPricePlotLine' + openLongPrices[i],
            value: Number(openLongPrices[i]),
            color: '#00ffff',
            dashStyle: 'shortdash',
            width: 1,
            label: {
              useHTML: true,
              align: 'right',
              // this.plotLinesLabelTextWidth * this.plotLinesLabelTextMultiple * 2,
              x: 100,
              text: '<b>' + this.$t('price.openLong') +': ' + Number(openLongPrices[i]).toFixed(this.getDecimalNumbers()) + '</b>'
            }
          };
          // this.chart.yAxis[0].addPlotLine(plotLine);
          this.addPlotLine(plotLine);
        }
        // 开空
        let openShortPrices = newVal.openShortPrices;
        for(let i = 0, len = openShortPrices.length; i < len; i++){
          // 开空仓价的横线
          let plotLine = {
            id: 'openShortPricePlotLine' + openShortPrices[i],
            value: Number(openShortPrices[i]),
            color: '#f07a14',
            dashStyle: 'shortdash',
            width: 1,
            label: {
              useHTML: true,
              align: 'right',
              //this.plotLinesLabelTextWidth * this.plotLinesLabelTextMultiple * 2
              x: 100,
              text: '<b>' + this.$t('price.openShort') +': ' + Number(openShortPrices[i]).toFixed(this.getDecimalNumbers()) + '</b>'
            }
          };
          // this.chart.yAxis[0].addPlotLine(plotLine);
          this.addPlotLine(plotLine);
        }
      },
      // 监听止盈价和止损价的标示线
      openPositionsPrices(newVal, oldVal){
        if (newVal == null) {
          return;
        }
        let oldEntryLongPrices = oldVal.entryLongPrices;
        let oldEntryShortPrices = oldVal.entryShortPrices;
        let oldTakeProfitPrices = oldVal.takeProfitPrices;
        let oldStopLossPrices = oldVal.stopLossPrices;
        // 删除旧的标示线
        for(let i = 0, len = oldEntryLongPrices.length; i < len; i++){
          this.chart.yAxis[0].removePlotLine('entryLongPricePlotLine' + oldEntryLongPrices[i]);
        }
        for(let i = 0, len = oldEntryShortPrices.length; i < len; i++){
          this.chart.yAxis[0].removePlotLine('entryShortPricePlotLine' + oldEntryShortPrices[i]);
        }
        for(let i = 0, len = oldTakeProfitPrices.length; i < len; i++){
          this.chart.yAxis[0].removePlotLine('takeProfitPricePlotLine' + oldTakeProfitPrices[i]);
        }
        for(let i = 0, len = oldStopLossPrices.length; i < len; i++){
          this.chart.yAxis[0].removePlotLine('stopLossPricePlotLine' + oldStopLossPrices[i]);
        }
        // 添加新的标示线
        // 已开多仓进入价
        let entryLongPrices = newVal.entryLongPrices;
        for(let i = 0, len = entryLongPrices.length; i < len; i++){
          // 进入开多价的横线
          let plotLine = {
            id: 'entryLongPricePlotLine' + entryLongPrices[i],
            value: Number(entryLongPrices[i]),
            color: '#4CAF50',
            dashStyle: 'solid',
            width: 2,
            label: {
              useHTML: true,
              align: 'left',
              // this.plotLinesLabelTextWidth * this.plotLinesLabelTextMultiple * 3
              x: 0,
              style:{
                zIndex: 1,
              },
              text: '<b>' + this.$t('price.entryLong') +': ' + Number(entryLongPrices[i]).toFixed(this.getDecimalNumbers()) + '</b>'
            }
          };
          // this.chart.yAxis[0].addPlotLine(plotLine);
          this.addPlotLine(plotLine);
        }
        // 已开空仓进入价
        let entryShortPrices = newVal.entryShortPrices;
        for(let i = 0, len = entryShortPrices.length; i < len; i++){
          // 进入开空价的横线
          let plotLine = {
            id: 'entryShortPricePlotLine' + entryShortPrices[i],
            value: Number(entryShortPrices[i]),
            color: '#F44336',
            dashStyle: 'solid',
            width: 2,
            label: {
              useHTML: true,
              align: 'left',
              // this.plotLinesLabelTextWidth * this.plotLinesLabelTextMultiple * 3
              x: 0,
              style:{
                zIndex: 1,
              },
              text: '<b>' + this.$t('price.entryShort') +': ' + Number(entryShortPrices[i]).toFixed(this.getDecimalNumbers()) + '</b>'
            }
          };
          // this.chart.yAxis[0].addPlotLine(plotLine);
          this.addPlotLine(plotLine);
        }
        // 止盈
        let takeProfitPrices = newVal.takeProfitPrices;
        for(let i = 0, len = takeProfitPrices.length; i < len; i++){
          // 止盈价的横线
          let plotLine = {
            id: 'takeProfitPricePlotLine' + takeProfitPrices[i],
            value: Number(takeProfitPrices[i]),
            color: '#25a69a',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              useHTML: true,
              align: 'left',
              style:{
                zIndex: 1,
              },
              x: this.plotLinesLabelTextWidth * this.plotLinesLabelTextMultiple * 1,
              text: '<b>' + this.$t('price.take') +': ' + Number(takeProfitPrices[i]).toFixed(this.getDecimalNumbers()) + '</b>'
            }
          };
          // this.chart.yAxis[0].addPlotLine(plotLine);
          this.addPlotLine(plotLine);
        }
        // 止损
        let stopLossPrices = newVal.stopLossPrices;
        for(let i = 0, len = stopLossPrices.length; i < len; i++){
          // 止损价的横线
          let plotLine = {
            id: 'stopLossPricePlotLine' + stopLossPrices[i],
            value: Number(stopLossPrices[i]),
            color: '#ef5350',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              useHTML: true,
              align: 'left',
              style:{
                zIndex: 1,
              },
              x: 0,
              text: '<b>' + this.$t('price.stop') +': ' + Number(stopLossPrices[i]).toFixed(this.getDecimalNumbers()) + '</b>'
            }
          };
          // this.chart.yAxis[0].addPlotLine(plotLine);
          this.addPlotLine(plotLine);
        }
      },
      //监听黑暗模式
      darkMode(){
        // 导航器按钮的颜色
        this.option.navigation.buttonOptions.symbolStroke = this.darkMode ? 'white':'black';
        this.option.navigation.buttonOptions.theme.fill = this.darkMode ? '#303030':'#EEEEEE';
        // 最大值和最小值的字体颜色
        this.option.plotOptions.candlestick.dataLabels.color = this.darkMode ? '#ffffff':'#212121';
        this.getHeikinAshiChartData(false);
      },
      // 监听成交价
      marketPrice(newVal, oldVal){
        if (newVal != null && newVal[this.symbol] != null && newVal[this.symbol].current != null) {
          let currentPrice = Number(newVal[this.symbol].current.price);
          // 示例: [价格, 时间戳][9100, 1595225751599]
          this.realtimeTrade = [currentPrice, +new Date()];
          this.getUnfinishedBar();
        }
      },
      // 监听图表范围
      chartRangeSelector(newVal, oldVal){
        this.calsChartStartDatetime();
        this.getHeikinAshiChartData(true);
      },
      // 监听数值变化变为数字类型
      chartPlotLineParam: {
        handler(newObject, oldObject) {
          this.chartPlotLineParam.value = Number(this.chartPlotLineParam.value);
        },
        deep: true
      },
      // 监听货币
      currency(newVal, oldVal){
        this.chartPlotLineParam.currency = newVal;
        // 查询图标指示线
        this.getChartPlotLines();
      }
    },
    created(){
      this.getParams();
    },
    components: { html2canvas, FiLogo, ColorPickerDialog },
    mounted(){
      Highcharts.setOptions({
        global: {
          // 是否使用UTC时间
          useUTC: this.time == 'UTCTime'
        },
        lang: {
          noData: this.$t('highcharts.noData'),
          contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
          printChart: this.$t('highcharts.printChart'),
          resetZoom: this.$t('highcharts.resetZoom'),
          resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
          downloadPNG: this.$t('highcharts.downloadPNG'),
          downloadJPEG: this.$t('highcharts.downloadJPEG'),
          downloadPDF: this.$t('highcharts.downloadPDF'),
          downloadSVG: this.$t('highcharts.downloadSVG')
        }
      });
      // 初始化加载数据渲染图表
      this.initEvents();
      // 计算图表开始时间
      this.calsChartStartDatetime();
      // 查询图表数据
      this.getHeikinAshiChartData(true);
      // 查询自定义的指示线
      this.getChartPlotLines();
      if (this.responsive == 'mobile'){
        this.option.yAxis[0].offset = 80;
      }else{
        this.option.yAxis[0].offset = 120;
      }
      // 最大值和最小值的字体颜色
      this.option.plotOptions.candlestick.dataLabels.color = this.darkMode ? '#ffffff':'#212121';
      this.chart = Highcharts.stockChart(this.id, this.option);
      // 十分钟隐式刷新一次K线图
      this.autoRetreshTimer = setInterval(() => {
        this.getHeikinAshiChartData(false);
      }, 600000);
    },
    computed: {
      ...mapGetters(['responsive', 'darkMode', 'time', 'timeZones', 'spotSymbolInfos',  'futuresDecimalNumbers', 'swapUsdtSymbolInfos', 'marketPrice']),
      // 标示线文本距左侧倍数
      plotLinesLabelTextMultiple(){
        if (this.responsive == 'mobile') {
          return 0.5;
        } else {
          return 1;
        }
      }
    },
    methods:{
      // 查询HeikinAshiChart数据isShowLoading=boolean
      getHeikinAshiChartData(isShowLoading){
        if (this.chartLoading) {
          return;
        }
        this.option.navigation.buttonOptions.symbolStroke = this.darkMode ? 'white':'black';
        this.option.navigation.buttonOptions.theme.fill = this.darkMode ? '#303030':'#EEEEEE';
        // 如果需要显示加载中，则开启加载中
        if (isShowLoading) {
          this.chartLoading = true;
        }
        let api = config.baseUrl + '/heikin-ashi/public/heikin-ashi?exchange=' + this.exchange + '&tradingCategory=' + this.tradingCategory + '&symbol=' + this.symbol + '&interval=' + this.interval + '&offset=' + this.timeZone.value;
        // 起始时间不为null就添加参数
        if (this.chartStartDatetime) {
          api += '&start=' + this.chartStartDatetime;
        }
        this.$http.get(api).then(response => {
          this.realtimeTrade = [];
          this.lastHeikinAshiChartData = [];
          let data = response.data;
          if (data.code == 200) {
            let list = data.result.data;
            // 最后一组数据
            this.lastHeikinAshiChartData = list[list.length - 1];
            // 删掉最后一组数据，用于实时计算
            list.splice(list.length - 1, 1);
            this.seriesData = list;
            this.nextBarTimestamp = data.result.nextBarTimestamp;
            // this.volumeData = data.result.volume;
            // 关闭加载中
            this.chartLoading = false;
            // this.chart = Highcharts.stockChart(this.id, this.option);
            this.getRangeSelectorButtons();
            this.getUnfinishedBar();
          } else if (data.code == 204) {
            // 关闭加载中
            this.chartLoading = false;
            this.seriesData = [];
          } else {
            // 关闭加载中
            this.chartLoading = false;
            this.seriesData = [];
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
          this.setDecimalNumbers();
        }, error => {
          this.chartLoading = false;
          this.seriesData = [];
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 初始化数据的点击事件
      initEvents(){
        let _this = this;
        let events = {
          click: function(e) {
            let data = 0;
            if (e.point.open > e.point.close) {
              // 下跌
              data = e.point.low;
            } else {
              // 上升
              data = e.point.high;
            }
            // _this.$emit('transferPrice', data);
          }
        };
        this.option.plotOptions.candlestick.events = events;
      },
      // 计算小数位
      getDecimalNumbers(){
        let decimalNumbers = 8;
        switch(this.tradingCategory){
          case 'spot': 
            if (this.exchange == null) {
              break;
            }
            let currentExchangeSpotSymbolInfos = this.spotSymbolInfos[this.exchange.toLowerCase()];
            for(let i = 0, len = currentExchangeSpotSymbolInfos.length; i < len; i++){
              if (currentExchangeSpotSymbolInfos[i].symbol == this.symbol) {
                decimalNumbers = currentExchangeSpotSymbolInfos[i].priceDecimalPlace;
                break;
              }
            }
            break;
          case 'futures': 
            decimalNumbers = this.futuresDecimalNumbers[this.symbol];
            break;
          case 'swap-usdt': 
            if (this.exchange == null) {
              break;
            }
            let currentExchangeSwapUsdtSymbolInfos = this.swapUsdtSymbolInfos[this.exchange.toLowerCase()];
            for(let i = 0, len = currentExchangeSwapUsdtSymbolInfos.length; i < len; i++){
              if (currentExchangeSwapUsdtSymbolInfos[i].symbol == this.symbol) {
                decimalNumbers = currentExchangeSwapUsdtSymbolInfos[i].priceDecimalPlace;
                break;
              }
            }
            break;
        }
        return decimalNumbers;
      },
      // 设置图表的小数位
      setDecimalNumbers(){
        let decimalNumbers = this.getDecimalNumbers();
        this.option.series[0].name = this.symbol;
        this.option.series[0].tooltip.valueDecimals = decimalNumbers;
        this.option.yAxis[0].labels.format = '{value:.' + decimalNumbers + 'f}';
        this.option.yAxis[0].crosshair.label.format = '{value:.' + decimalNumbers + 'f}';
        this.chart = Highcharts.stockChart(this.id, this.option);
      },
      // 获得未完成的块
      getUnfinishedBar(){
        // 程序员的两大疑惑：
        // 改改这儿，能运行了！为啥呢？
        // 改改那儿，报错了！为啥呢？
        if(this.chartLoading){
          // 如果数据还在加载中，不计算新块
          return;
        }
        // 先更新为历史数据
        let realtimeSeriesData = [].concat(this.seriesData);
        if (this.lastHeikinAshiChartData.length < 7) {
          return;
        }
        // 计算OHLC
        let high = this.lastHeikinAshiChartData[2];
        let low = this.lastHeikinAshiChartData[3];
        let close = this.lastHeikinAshiChartData[4];
        // 实时成交数据有的话需要计算新的OHLC
        if (this.realtimeTrade.length >= 2) {
          let price = this.realtimeTrade[0];
          let timestamp = this.realtimeTrade[1];
          // 当实时成交的时间戳大于等于[nextBarTimestamp]时，一组OHLC收集完成
          if (timestamp < this.nextBarTimestamp) {
            // 还在最后一个bar里面
            if (price > high) { high = price; }
            if (price < low) { low = price; }
            close = price;
            // 最新的一组bar
            let heikinAshiData = [this.lastHeikinAshiChartData[0], this.lastHeikinAshiChartData[1], high, low, close, this.lastHeikinAshiChartData[5], this.lastHeikinAshiChartData[6]];
            // 覆盖上一次的数据
            this.lastHeikinAshiChartData = heikinAshiData;
            // 放入实时的数据组进行渲染
            realtimeSeriesData.push(heikinAshiData);
          } else {
            // 新的一组OHLC
            let heikinAshiData = [this.nextBarTimestamp, price, price, price, price, 0, formatISO8601Time(this.nextBarTimestamp)];
            // 把最后一组追加数据到[seriesData]中，这样下次的[realtimeSeriesData]就会追加一组新的OHLC数据
            // 复制一组数据
            let lastHeikinAshiChartData = [].concat(this.lastHeikinAshiChartData);
            // 保存在持久化的数据组里
            this.seriesData.push(lastHeikinAshiChartData);
            // 新的成交量的数组
            // let newestVolumeData = [].concat(this.volumeData);
            // let volumeArray = [this.nextBarTimestamp, 0];
            // newestVolumeData.push(volumeArray);
            // this.volumeData = newestVolumeData;
            // 在将新的数据保存
            this.lastHeikinAshiChartData = heikinAshiData;
            // 重新生成实时的数据
            realtimeSeriesData = [].concat(this.seriesData);
            realtimeSeriesData.push(heikinAshiData);
            // 更新下一次的时间
            this.nextBarTimestamp += this.interval * 3600000;
          }
          // this.option.series[0].data = this.seriesData;
        } else {
          // 没有的话就拼上最后一个块
          // 放入实时的数据组进行渲染
          realtimeSeriesData.push(this.lastHeikinAshiChartData);
        }
        this.realtimeSeriesData = realtimeSeriesData;
        // 此时最新的这一组数据
        let newestHeikinAshiData = realtimeSeriesData[realtimeSeriesData.length - 1];
        // 计算收盘的横线，默认灰色
        let plotLinesColor = '#9e9e9e';
        if (newestHeikinAshiData[1] > newestHeikinAshiData[4]) {
          // 下跌
          plotLinesColor = this.darkMode ? '#792c38': '#ef5350';
        } else {
          // 上涨
          plotLinesColor = this.darkMode ? '#255d4b':'#25a69a';
        }
        // 收盘价的横线
        let plotLine = {
          id: 'closePricePlotLine',
          value: newestHeikinAshiData[4],
          color: plotLinesColor,
          dashStyle: 'shortdash',
          width: 1,
          label: {
            useHTML: true,
            align: 'right',
            x: 100,
            text: '<b>' + newestHeikinAshiData[4].toFixed(this.getDecimalNumbers()) + '</b>'
          }
        };
        this.chart.yAxis[0].removePlotLine('closePricePlotLine');
        // this.chart.yAxis[0].addPlotLine(plotLine);
        this.addPlotLine(plotLine);
      },
      // 生成范围选择器的按钮
      getRangeSelectorButtons(){
        let len = this.seriesData.length;
        let timestamp = 0;
        // 最后的时间戳
        let lastTimestamp = this.seriesData[len - 1][0];
        if (len > 40) {
          // 取出倒数第40个块的时间
          timestamp = this.seriesData[len - 40][0];
        } else {
          // 取出第1个块的时间
          timestamp = this.seriesData[0][0];
        }
        // 距离现在的时间差
        let timestampDifference = lastTimestamp - timestamp;
        // 时间差的小时
        let hours = Math.ceil(timestampDifference / 3600000);
        let buttons = [
            { type: 'hour', count: hours, text: this.$t('common.auto') },
            { type: 'all', text: this.$t('common.all') }
          ];
        this.option.rangeSelector.buttons = buttons;
        // 默认选中最后一个，就是All
        this.option.rangeSelector.selected = buttons.length - 1;
        this.chart = Highcharts.stockChart(this.id, this.option);
      },
      //全屏
      toggleFullscreen() {
        if (this.chart.fullscreen) {
          this.chart.fullscreen.toggle();
        }
      },
      // 加载参数
      getParams(){
        // 周期
        let interval = localStorage.getItem('iobots-heikinAshi-' + this.tradingCategory + '-interval');
        if (interval == null) {
          localStorage.setItem('iobots-heikinAshi-' + this.tradingCategory + '-interval', this.interval);
        } else {
          this.interval = interval;
        }
        // 时区
        let timeZone = JSON.parse(localStorage.getItem('iobots-heikinAshi-' + this.tradingCategory + '-timeZone'));
        if (timeZone == null) {
          localStorage.setItem('iobots-heikinAshi-' + this.tradingCategory + '-timeZone', JSON.stringify(this.timeZone));
        } else {
          this.timeZone = timeZone;
        }
        // 隐藏订单指示线
        let hideOrderPlotLines = localStorage.getItem('iobots-heikinAshi-' + this.tradingCategory + '-hideOrderPlotLines');
        if (hideOrderPlotLines == null) {
          localStorage.setItem('iobots-heikinAshi-' + this.tradingCategory + '-hideOrderPlotLines', this.hideOrderPlotLines ? 1 : 0);
        } else {
          this.hideOrderPlotLines = (hideOrderPlotLines == 1);
        }
        // K线样式
        let hollowCandleStyle = localStorage.getItem('iobots-heikinAshi-' + this.tradingCategory + '-hollowCandleStyle');
        if (hollowCandleStyle == null) {
          localStorage.setItem('iobots-heikinAshi-' + this.tradingCategory + '-hollowCandleStyle', this.hollowCandleStyle ? 1 : 0);
        } else {
          this.hollowCandleStyle = (hollowCandleStyle == 1);
        }
      },
      // 缓存参数
      setParams(){
        localStorage.setItem('iobots-heikinAshi-' + this.tradingCategory + '-interval', this.interval);
        localStorage.setItem('iobots-heikinAshi-' + this.tradingCategory + '-timeZone', JSON.stringify(this.timeZone));
        localStorage.setItem('iobots-heikinAshi-' + this.tradingCategory + '-hideOrderPlotLines', this.hideOrderPlotLines ? 1 : 0);
        localStorage.setItem('iobots-heikinAshi-' + this.tradingCategory + '-hollowCandleStyle', this.hollowCandleStyle ? 1 : 0);
      },
      // 生成图片
      generateImage() {
        // 正在截图
        this.isTakingScreenshot = true;
        let _this = this;
        // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
        // 延时操作，不要问为什么，不延迟不可以
        setTimeout(function() {
          html2canvas(_this.$refs.heikinAshiChartImage, {
            backgroundColor: _this.darkMode ? '#303030' : '#EEEEEE'
          }).then((canvas) => {
            let url = canvas.toDataURL('image/png');
            _this.imageBase64Url = url;
            // 生成截图完毕
            _this.isTakingScreenshot = false;
            // 打开窗口
            _this.uploadBase64Loading = true;
            _this.heikinAshiChartImageDialog = true;
            // 把生成的base64位图片上传到服务器,生成在线图片地址
            _this.uploadBase64();
          })
        }, 1)
      },
      // 点击下载图片
      saveImage(){
        let a = document.createElement('a');
        // 设置图片地址
        a.href = this.imageBase64Url;
        // 设定下载名称
        a.download = 'HeikinAshi-' + this.exchange + '-' + this.symbol;
        // 点击触发下载
        a.click();
      },
      // 删上传base64
      uploadBase64(){
        this.uploadBase64Loading = true;
        let api = config.baseUrl + '/upload/base64';
        let param = { base64: this.imageBase64Url };
        this.$http.post(api, param).then(response => {
          let data = response.data;
          this.uploadBase64Loading = false;
          if (data.code == 200) {
            this.imageHttpUrl = data.result;
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
        }, error => {
          this.uploadBase64Loading = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 复制成功回调函数
      copySuccess(){
        this.$store.dispatch('snackbarMessageHandler', this.$t('common.copied'));
      },
      // 复制失败回调函数
      copyError(){
        this.$store.dispatch('snackbarMessageHandler', this.$t('common.copyFailed'));
      },
      // 接收传来的成交对象
      reveiceTransferTrade(trade){
        // 示例: [价格, 时间戳][9100, 1595225751599]
        this.realtimeTrade = trade;
        this.getUnfinishedBar();
      },
      // 计算开始时间
      calsChartStartDatetime(){
        let bars = 50;
        if (this.chartRangeSelector == 'auto') {
            bars = 50;
        } else if (this.chartRangeSelector == 'all'){
            bars = 0;
        } else {
            bars = Number(this.chartRangeSelector);
        }
        if(bars == 0) {
            this.chartStartDatetime = null;
        } else {
            // 自动数据计算N个块的时间
            let startTimestamp = +new Date() - Number(this.interval) * 3600000 * bars;
            this.chartStartDatetime = formatISO8601Time(startTimestamp);
        }
      },
      // 添加指示线
      addPlotLine(plotLine){
        // 如果是收盘价指示线则直接添加
        if (plotLine.id == 'closePricePlotLine') {
          this.chart.yAxis[0].addPlotLine(plotLine);
        } else if (!this.hideOrderPlotLines) {
          this.chart.yAxis[0].addPlotLine(plotLine);
        }
      },
      // 查询周期的文本
      getIntervalLabel(interval){
        interval = Number(interval);
        switch(interval){
          case 1: return this.$t('inteval.1h');
          case 2: return this.$t('inteval.2h');
          case 4: return this.$t('inteval.4h');
          case 6: return this.$t('inteval.6h');
          case 12: return this.$t('inteval.12h');
          case 24: return this.$t('inteval.1d');
          case 72: return this.$t('inteval.3d');
          case 168: return this.$t('inteval.1w');
          default: return '';
        }
      },
      // 接收的弹窗
      receiveColorPickerDialog(color){
        this.chartPlotLineParam.color = color;
        this.colorPickerDialog = false;
      },
      // 提交新增指示线请求
      submitChartPlotLine(){
        this.chartPlotLineSubmitLoading = true;
        let api = config.baseUrl + '/chart/plot/line/private/line';
        this.chartPlotLineParam.currency = this.currency;
        this.$http.post(api, this.chartPlotLineParam).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.chartPlotLinesDialog = false;
            this.chartPlotLineParam.value = 0;
            // 查询图标指示线
            this.getChartPlotLines();
          }
          this.chartPlotLineSubmitLoading = false;
          this.$store.dispatch('snackbarMessageHandler', data.message);
        }, error => {
          this.chartPlotLineSubmitLoading = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        })
      },
      // 查询图标指示线
      getChartPlotLines(){
        this.selectedDeleteChartPlotLineId = null;
        let api = config.baseUrl + '/chart/plot/line/private/lines?chartType=HeikinAshi&currency=' + this.currency;
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            let chartPlotlines = data.result;
            chartPlotlines.forEach(line => {
              // 自定义的横线
              let plotLine = {
                id: line.id,
                value: Number(line.value),
                color: line.color,
                dashStyle: line.dashStyle,
                width: line.width,
                className: 'pointer',
                zIndex: 3,
                events: {
                  click: () => {
                    this.selectedDeleteChartPlotLineId = line.id;
                  }
                }
              };
              this.addPlotLine(plotLine);
            })
          }
        }, error => {
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        })
      },
      // 刪除图表指示线
      deleteChartPlotLine(){
        let api = config.baseUrl + '/chart/plot/line/private/line?id=' + this.selectedDeleteChartPlotLineId;
        this.$http.delete(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.chart.yAxis[0].removePlotLine(this.selectedDeleteChartPlotLineId);
          }
          this.selectedDeleteChartPlotLineId = null;
        })
      }
    },
    beforeDestroy() {
      // 关闭定时器
      clearInterval(this.autoRetreshTimer);
    }
  }
</script>
<style scoped lang="scss">
  svg.highcharts-root{
    font-family: "Lexend Deca", sans-serif!important;
  }
  .v-menu__content{
    z-index: 0!important;
  }
  .chart-credits{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .chart-credits-text{
      margin-left: 15px;
      font-size: 18px;
    }
  }
</style>