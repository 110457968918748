<template>
  <div>
    <div class="text-center">
        <h5>{{ $t('assets.assetTrend') }}</h5>
    </div>
    <!-- <div class="ml-2 mb-3 font-4x fw-bold">
      <div v-if="responsive == 'PC'">
        <span v-if="option.series[0].data.length > 0">{{ '$' + formatNum(option.series[0].data[option.series[0].data.length - 1].toFixed(2)) }}</span>
        <span v-if="option.series[0].data.length == 0">--</span>
        <span class="mr-5 float-right" :class="[performance == '--' ? '' : (performance > 0 ? 'green--text' : 'red--text')]">{{ performance == '--' ? '' : (performance > 0 ? ('+' + formatNum(performance.toFixed(2)) + '%') : (formatNum(performance.toFixed(2)) + '%')) }}
        </span>
      </div>
      <div v-if="responsive == 'mobile' || responsive == 'pad'">
        <span v-if="option.series[0].data.length > 0">{{ '$' + formatNum(option.series[0].data[option.series[0].data.length - 1].toFixed(2)) }}</span>
        <span v-if="option.series[0].data.length == 0">--</span>
        <br>
        <span :class="[performance == '--' ? '' : (performance > 0 ? 'green--text' : 'red--text')]">{{ performance == '--' ? '' : (performance > 0 ? ('+' + formatNum(performance.toFixed(2)) + '%') : (formatNum(performance.toFixed(2)) + '%')) }}
        </span>
      </div>
    </div> -->
    <div class="ml-2 mb-2 fs-12">
      <span @click="day=7" class="pointer mr-1" :class="day == 7 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1w') }}</span>
      <span @click="day=30" class="pointer ml-1 mr-1" :class="day == 30 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1m') }}</span>
      <span @click="day=90" class="pointer ml-1 mr-1" :class="day == 90 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.3m') }}</span>
      <span @click="day=365" class="pointer ml-1 mr-1" :class="day == 365 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1y') }}</span>
      <span @click="day=0" class="pointer ml-1 mr-1" :class="day == 0 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.all') }}</span>
      <span class="mr-4 float-right">
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.fullScreen')" @click="toggleFullscreen">mdi-fullscreen</v-icon>
      </span>
      <!-- <div class="grey--text">
        <span>{{$t('highcharts.assetsTrendLineChart.mouseDragToZoom')}}</span>
        <a class="pointer grey--text mr-3 del-underline float-right" :href="domainName">{{$t('highcharts.dataFromIOB')}}</a>
      </div> -->
    </div>
    <div :id="id" class="mr-2 mt-3" style="height: 300px"></div>
  </div>
</template>
<style>
  .lineblue--text{
    color: #2196f3 !important;
    caret-color: #2196f3 !important;
  }
   svg.highcharts-root{
    font-family: "Lexend Deca", sans-serif!important;
  }
</style>
<script>
  import config from '@/config.js';
  import Highcharts from 'highcharts/highstock';
  import { mapGetters } from "vuex";
  import Bus from "@/components/bus/Bus";
  export default {
    data() {
      return {
        day: 90,
        currency:'USD',
        id: 'assetsTrendLineChart',
        quasarNav: null,
        domainName: config.domainName,
        performance: '--',
        option:{
          // 图表类型
          chart: {
            backgroundColor: this.darkMode ? 'dark' : '',
            renderTo: 'assetsTrendLineChart',
            type: 'line'
          },
          // 标题
          title: {
            text: null,
            align:'left',
            style: {
              color: '#9E9E9E',
            },
          },
          // x轴
          xAxis: {
            categories:[],
            tickInterval: 20,
            type: 'datetime',
            dateTimeLabelFormats: {
              millisecond: '%m/%d/%Y %H:%M:%S',
              second: '%m/%d/%Y %H:%M:%S',
              minute: '%m/%d/%Y %H:%M',
              hour: '%m/%d/%Y %H:%M',
              day: '%m/%d',
              week: '%m/%d',
              month: '%m/%Y',
              year: '%Y'
            }
          },
          // y轴
          yAxis: {
            title: {
              text: null
            },
            offset: 40,
            labels: {
              align: "left",
              x: -10
            },
            visible: true,
            // 横线宽度设置为0表示不显示横线
            gridLineWidth: 0,
            opposite:true
          },
          // 鼠标悬浮提示框
          tooltip: {
            valueDecimals: 2,
            valuePrefix: '$',
            valueSuffix: '',
            followTouchMove: true,
            dateTimeLabelFormats: {
              millisecond: '%m/%d/%Y %H:%M:%S UTC',
              second: '%m/%d/%Y %H:%M:%S UTC',
              minute: '%m/%d/%Y %H:%M UTC',
              hour: '%m/%d/%Y %H:%M UTC',
              day: '%m/%d/%Y %H:%M UTC',
              week: '%m/%d %H:%M UTC',
              month: '%m/%Y',
              year: '%Y'
            }
          },
          // 数据
          series: [
            {
            // type: 'spline',
              name: this.$t('highcharts.assetsTrendLineChart.totalAssets'),
              data: [],
              color: {
                linearGradient: {
                  x1: 0,
                  x2: 0,
                  y1: 0,
                  y2: 1
                },
                stops: [
                  [0, '#4CAF50'],
                  [1, '#FEE108']
                ]
              },
              lineWidth: 3,
            },
          ],
          noData: {
            style: {
              fontWeight: 'bold',
              fontSize: '15px',
              color: '#303030'
            }
          },
          // 图例导航
          legend: {
            enabled: false
          },
          // 图形参数
          plotOptions: {
            line: {
              lineWidth: 2,
              cursor: 'pointer',
              marker: {
               enabled: false
             }
            }
          },
          // 版权信息
          credits: {
            enabled: false
          },
          exporting: {
            enabled: false,
            filename: 'Assets-Trend-Line-Chart',
            buttons: {
              contextButton: {
                align: "left",
                verticalAlign: "top",
                x: 1,
                y: 0
              }
            }
          },
          navigation: {
            buttonOptions: {
                symbolStroke: '#ffffff',
                theme: {
                    fill: this.darkMode ? '' : 'black',
                },
            }
          },
        },
      }
    },
    props:{

    },
    watch: {
      day(val){
        this.getAssetsTrend();
      },
      currency(){
        if (this.quasarNav) {
          if (this.currency == 'BTC'){
            this.option.series[0].data = this.quasarNav.quasarNavInBTC;
          } else {
            this.option.series[0].data = this.quasarNav.quasarNavInUSD;
          }
        }
        // 重新渲染图表
        this.chart = Highcharts.chart(this.id, this.option);
      },
      darkMode(val){
        this.option.navigation.buttonOptions.symbolStroke = val ? 'white':'black';
        this.option.navigation.buttonOptions.theme.fill = val ? '#424242':'white';
        this.chart = Highcharts.chart(this.id, this.option);
      }
    },
    created(){

    },
    mounted() {
      this.getAssetsTrend();
      Highcharts.setOptions({ lang: {
        noData: this.$t('highcharts.noData'),
        contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
        printChart: this.$t('highcharts.printChart'),
        resetZoom: this.$t('highcharts.resetZoom'),
        resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
        downloadPNG: this.$t('highcharts.downloadPNG'),
        downloadJPEG: this.$t('highcharts.downloadJPEG'),
        downloadPDF: this.$t('highcharts.downloadPDF'),
        downloadSVG: this.$t('highcharts.downloadSVG')
      }});
      this.chart = Highcharts.chart(this.id, this.option);
      Bus.$on('refreshAssets', data => {
        this.getAssetsTrend();
      })
    },
    computed: {
      ...mapGetters(['darkMode', 'responsive'])
    },
    components: {

    },
    methods:{
      getAssetsTrend(){
        this.option.navigation.buttonOptions.symbolStroke = this.darkMode ? 'white':'black';
        this.option.navigation.buttonOptions.theme.fill = this.darkMode ? '#424242':'white';
        this.option.series[0].data = [];
        this.option.xAxis.categories = [];
        this.performance = '--';
        let api = config.baseUrl + "/asset/private/getZeroNav?day="+this.day;
        this.$http.get(api).then(res => {
          let data = res.data;
          if (data.code == 200){
            for (let i = 0; i < data.result.length ; i++) {
              this.option.xAxis.categories.push(this.formatDateFunction(data.result[i][0]));
              this.option.series[0].data.push(data.result[i][1]);
            }
            let values = this.option.series[0].data;
            let first = values[0];
            let last = values[values.length - 1];
            this.performance = ((last - first) / first) * 100;
            // 分析数据
            let analysisData = {
              totalValue: '$' + this.formatNum(this.option.series[0].data[this.option.series[0].data.length - 1].toFixed(2)),
              performance: this.performance,
              dataUpdateTimestamp: data.result[data.result.length - 1][0]
            };
            this.$emit('sendAnalysisData', analysisData);
            // 重新渲染图表
            this.chart = Highcharts.chart(this.id, this.option);
          }
        })
      },
      formatDateFunction(timestamp){
        if (timestamp == 0) {
          return '--';
        } else {
          if( "localTime" == localStorage.getItem("iobots-time")){
            return formatDate(timestamp);
          }else{
            return formatUTCDate(timestamp)
          }
        }
      },
      // 数字格式化
      formatNum(num) {
        return formatNumber(num);
      },
      //全屏
      toggleFullscreen() {
        if (this.chart.fullscreen) {
          this.chart.fullscreen.toggle();
        }
      },
    }
  }
</script>
