<template>
	<div>
        <!-- *******************颜色选择器的弹窗******************* -->
        <v-row>
            <v-dialog v-model="colorPickerDialog" persistent width="360">
                <v-card>
                    <v-card-title class="grey lighten-2">
                        <span class="black--text">{{ $t('strategy.colorPicker') }}</span>
                        <v-spacer></v-spacer>
                        <v-btn light icon @click="emitColor">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-container class="pb-0">
                        <v-row class="align-center justify-center pb-5">
                            <!-- v-if是为了刷新组件 -->
                            <color-picker v-if="colorPickerDialog" :color="color" @changeColor="changeColor" style="width: 250px"></color-picker>
                        </v-row>
                    </v-container>
                    <v-card-actions class="mx-5 pb-5">
                        <v-spacer></v-spacer>
                        <v-btn small tile class="blue white--text mr-7" @click="emitColor">
                          {{ $t('common.ok') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
	</div>
</template>
<script>
    import config from '@/config.js';
    import ColorPicker from '@caohenghu/vue-colorpicker';
    export default {
        data(){
            return {
                // 调色板选中的颜色
                color: '#9e9e9e',
            }
        },
        props:[ 'colorPickerDialog' ],
        components: { ColorPicker },
        created(){

        },
        mounted(){

        },
        computed: {

        },
        watch:{

        },
        methods: {
            // 改变颜色
            changeColor(color) {
                if (color) {
                    this.color = color.hex;
                }
            },
            // 关闭弹窗
            emitColor(){
                this.$emit("emitColorPickerDialog", this.color);
            }
        },
    }
</script>
<style>

</style>