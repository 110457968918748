import { render, staticRenderFns } from "./TradingCandleChart.vue?vue&type=template&id=394a727c&scoped=true&"
import script from "./TradingCandleChart.vue?vue&type=script&lang=js&"
export * from "./TradingCandleChart.vue?vue&type=script&lang=js&"
import style0 from "./TradingCandleChart.vue?vue&type=style&index=0&id=394a727c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "394a727c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/_vuetify-loader@1.7.2@vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VCol,VDialog,VForm,VIcon,VList,VListItem,VListItemTitle,VMenu,VProgressCircular,VRow,VSelect,VSlider,VSpacer,VTextField})
