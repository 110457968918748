<template>
    <div v-if="projects.length > 0">
        <v-row class="text-right">
            <v-col class="pa-0" cols="6">
                <span class="fs-18" v-if="changePercentage24h != null" :class="changePercentage24h.indexOf('-') > -1 ? 'red--text' : 'green--text'">{{ changePercentage24h }}</span>
            </v-col>
            <v-col class="pa-0" cols="6">
                <span class="fs-18" v-if="changePercentageUTCToday != null" :class="changePercentageUTCToday.indexOf('-') > -1 ? 'red--text' : 'green--text'">{{ changePercentageUTCToday }}</span>
            </v-col>
        </v-row>
        <v-row class="text-right">
            <v-col class="pa-0" cols="6">
                <span v-if="changePercentage24h != null" class="fs-12 grey--text">{{ $t('common.change24H') }}</span>
            </v-col>
            <v-col class="pa-0" cols="6">
                <span v-if="changePercentageUTCToday != null" class="fs-12 grey--text">{{ $t('common.changeToday') }}</span>
            </v-col>
        </v-row>
    </div>
</template>
<style>

</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    export default {
        data(){
            return {
                projects: [],
                changePercentage24h: null,
                changePercentageUTCToday: null,
                timer: null
            }
        },
        props: ['currency'],
        created(){

        },
        mounted(){
            this.getProjects();
            this.timer = setInterval(() => {
                this.getProjects();
            }, 10000);
        },
        computed: {

        },
        watch:{
            currency:{
                handler: function(newVal, oldVal){
                    this.getChangePercentageByCoin();
                },
                immediate: true
        　　}
        },
        methods: {
            getProjects(){
                let api = config.baseUrl + '/market/public/projects';
                this.$http.get(api).then(response => {
                    let data = response.data;
                    if (data.code == 200) {
                        this.projects = data.result;
                    } else {
                        this.projects = [];
                    }
                    this.getChangePercentageByCoin();
                });
            },
            getChangePercentageByCoin(){
                for(let i = 0, len = this.projects.length; i < len; i++){
                    if (this.currency == this.projects[i].coin) {
                        this.changePercentage24h = this.projects[i].changePercentage24h;
                        this.changePercentageUTCToday = this.projects[i].changePercentageUTCToday;
                        return;
                    }
                }
                // 如果没有匹配到return，就赋值为null
                this.changePercentage24h = null;
                this.changePercentageUTCToday = null;
            },
        },
        beforeDestroy() {
            if(this.timer) {
                clearInterval(this.timer);
                this.timer = null;
            }
        },
    }
</script>
