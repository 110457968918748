<template>
    <v-autocomplete v-model="searchCoinSelectedId" :label="$t('common.searchCoin')" :search-input.sync="searchCoinInput" :items="searchCoinSelections" item-text="symbol" item-value="id" style="width: 200px;" prepend-inner-icon="mdi mdi-magnify" append-icon="" dense solo outlined flat filled hide-details hide-no-data clearable allow-overflow @change="goToTrading">
        <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.symbol"></v-list-item-title>
              <v-list-item-subtitle v-text="item.tradingCategory.toUpperCase()"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              {{ getExchangeNameByCode(item.exchange) }}
            </v-list-item-action>
          </template>
    </v-autocomplete>
</template>
<style>

</style>
<script>
    import config from '@/config.js';
    import { mapGetters} from "vuex";
    export default {
        data(){
            return {
                // 输入的搜索的货币
                searchCoinInput:null,
                // 选中的输入的搜索的货币
                searchCoinSelectedId: null,
                // 选中的输入的搜索的货币
                searchCoinSelected: null,
                // 输入的搜索货币的符合条件的集合
                searchCoinSelections: [],
            }
        },
        created(){

        },
        mounted(){
            
        },
        computed: {
            ...mapGetters(['user', 'exchanges', 'spotSymbolInfos', 'futuresSymbolInfos', 'swapUsdtSymbolInfos']),
        },
        watch:{
            searchCoinInput (val) {
                if (!val) {
                    this.searchCoinSelections = [];
                    return;
                }
                return val && val !== this.searchCoinSelectedId && this.querySearchCoinSelections(val);
            },
        },
        methods: {
            querySearchCoinSelections (v) {
                let exchangeCodes = this.exchanges.map(ex => ex.code);
                let _this = this;
                exchangeCodes.forEach(code => {
                    // spot
                    let spotSymbols = this.spotSymbolInfos[code].filter(symbolObject => {
                        return (symbolObject || '').symbol.toLowerCase().indexOf((v || '').toLowerCase()) > -1;
                    });
                    this.searchCoinSelections = [...this.searchCoinSelections, ...spotSymbols];
                    // futures
                    let futuresSymbols = this.futuresSymbolInfos[code].filter(symbolObject => {
                        return (symbolObject || '').symbol.toLowerCase().indexOf((v || '').toLowerCase()) > -1;
                    });
                    this.searchCoinSelections = [...this.searchCoinSelections, ...futuresSymbols];
                    //swap-usdt
                    let swapUsdtSymbols = this.swapUsdtSymbolInfos[code].filter(symbolObject => {
                        return (symbolObject || '').symbol.toLowerCase().indexOf((v || '').toLowerCase()) > -1;
                    });
                    this.searchCoinSelections = [...this.searchCoinSelections, ...swapUsdtSymbols];
                });
            },
            getExchangeNameByCode(code){
                let filterExchange = this.exchanges.filter(ex => ex.code == code);
                return filterExchange.length > 0 ? filterExchange[0].name : code;
            },
            goToTrading(){
                let filterSelections = this.searchCoinSelections.filter(symbolObject => symbolObject.id == this.searchCoinSelectedId);
                if(filterSelections.length > 0){
                    this.searchCoinSelected = filterSelections[0];
                    switch(this.searchCoinSelected.tradingCategory){
                        case 'spot': this.goToSpot(); break;
                        case 'futures': this.goToFutures(); break;
                        case 'swap-usdt': this.goToSwapUsdt(); break;
                    }
                }
            },
            // 跳转到Spot
            goToSpot() {
                if (this.user.token) {
                    if (this.user.role == "TRADER") {
                        this.$router.push({
                            name: "spotTrading",
                            params: {
                                exchangeCode: this.searchCoinSelected.exchange,
                                symbol: this.searchCoinSelected.symbol
                            }
                            });
                            this.$store.dispatch("signalHeaderTitleHandler", 'signals');
                            this.$store.dispatch("tradingHeaderTitleHandler", 'spot');
                            this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
                        } else {
                            this.$store.dispatch("snackbarMessageHandler", this.$t("header.snackbarMessage"));
                        }
                } else {
                    this.gotoSignIn();
                }
            },
            // 跳转到goToFutures
            goToFutures() {
                if (this.user.token) {
                    if (this.user.role == "TRADER") {
                        this.$router.push({
                            name: "futuresTrading",
                            params: {
                                exchangeCode: this.searchCoinSelected.exchange,
                                symbol: this.searchCoinSelected.symbol
                            }
                            });
                            this.$store.dispatch("signalHeaderTitleHandler", 'signals');
                            this.$store.dispatch("tradingHeaderTitleHandler", 'futures');
                            this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
                        } else {
                            this.$store.dispatch("snackbarMessageHandler", this.$t("header.snackbarMessage"));
                    }
                } else {
                    this.gotoSignIn();
                }
            },
            // 跳转到SwapUsdt
            goToSwapUsdt() {
                if (this.user.token) {
                    if (this.user.role == "TRADER") {
                        this.$router.push({
                            name: "swapUsdtTrading",
                            params: {
                                exchangeCode: this.searchCoinSelected.exchange,
                                symbol: this.searchCoinSelected.symbol
                            }
                        });
                        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
                        this.$store.dispatch("tradingHeaderTitleHandler", 'swapUsdt');
                        this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
                    } else {
                        this.$store.dispatch("snackbarMessageHandler", this.$t("header.snackbarMessage"));
                    }
                } else {
                    this.gotoSignIn();
                }
            },
        },
    }
</script>
