<template>
    <div>
        <v-row class="py-0 my-0" dense>
            <v-col class="py-0 my-0">
                <v-select :items="typeItems" v-model="page" hide-details dense outlined class="caption"></v-select>
            </v-col>
        </v-row>
    </div>
</template>
<style>
</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    export default {
        data(){
            return{
                typeItems:[this.$t("header.spot"),this.$t("header.futures"),this.$t("header.swapUsdt")]
            }
        },
        created(){

        },
        mounted(){

        },
        computed: {
            ...mapGetters(["darkMode", "defaultExchange"]),
            page:{
            	get () {
            		return this.$store.getters.tradingTypePage;
		        },
		        set (val) {
                    this.$store.commit('tradingTypePageHandler', val)
                    if(val == this.$t("header.spot")){
                        this.$router.push({ 
                            name: "spotTrading",
                            params: {
                                exchangeCode: this.defaultExchange,
                                symbol: "BTC-USDT"
                            }
                        });
                    }else if(val == this.$t("header.futures")){
                        this.$router.push({
                            name: "futuresTrading",
                            params: {
                                exchangeCode: this.defaultExchange,
                                symbol: "PI_BTCUSD"
                            }
                        });
                    }else if(val == this.$t("header.swapUsdt")){
                        this.$router.push({
                        name: "swapUsdtTrading",
                            params: {
                                exchangeCode: this.defaultExchange,
                                symbol: "BTC-USDT-SWAP"
                            }
                        });
                    }
		        }
            }
        },
        watch:{

        },
        methods: {

        },
    }
</script>
